<template>
  <div :class="cssClass" v-if="hide">
    <Header />
    <div :class="$style.content">
      <aside :class="$style.sidebar">
        <Sidebar v-if="menu.length > 0 && render" />
      </aside>
      <main :class="$style.main">
        <slot />
      </main>
    </div>
    <Footer auth />
  </div>
</template>

<script>
import Header from "Components/layout/user/Header";
import Footer from "Components/layout/Footer";
import Sidebar from "Components/layout/user/sidebar/Sidebar";
import { routeVerification } from "./HeaderMenuApi";
export default {
  name: "Page",
  data() {
    return {
      render: true,
      hide: false,
    };
  },
  components: {
    Header,
    Footer,
    Sidebar,
  },
  watch: {
    menu() {
      if (this.menu.length > 0) {
        this.render = false;
        this.$nextTick(() => {
          this.render = true;
        });
        this.show = true;
      }
    },
  },
  computed: {
    menu() {
      return this.$store.state.common.subMenu;
    },
    isMenuCollapsed() {
      return this.$store.state.common.isMenuCollapsed;
    },
    cssClass() {
      return [
        this.$style.page,
        this.isMenuCollapsed ? this.$style.pageCollapsed : "",
      ];
    },
  },
  created() {
    this.verificationPage();
  },
  methods:{
    //sagar 14-07-2023 add this function for page verification when user manually change route
    async verificationPage() {
      await routeVerification({ routeName: this.$route.fullPath }).then(
        (res) => {
          if (res.data === 0) {
            this.$router.push({ path: "/unauthorize" });
          } else {
            this.hide = true;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" module>
.page {
  min-height: 100%;
}
.content {
  // display: flex;
  display: -webkit-box;
  min-height: calc(100vh - 52px);
}
.sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  background: #eeedf2;
  width: 230px;
  padding-bottom: 37px;
  transition: width 200ms ease;
  .pageCollapsed & {
    width: 50px;
  }
  position: relative;
  top: -35px;
  padding: 0 5px;
}
.main {
  flex-grow: 1;
  padding: 15px 15px 50px;
  // padding: 30px 30px 50px;
  background: #fff;
  // margin-left: 218px;
  // margin-top:190px;
}
</style>
