<template>
    <div :class="cssClass">
        <div :class="$style.footerDiv">
            <div :class="$style.dateDiv">
                {{currentDate}}
            </div>

            <marquee Scrollamount=5 >
            <div v-if="pageCheck">
                <span style="font-size: 20px;">“</span>
                {{ thoughtsOfTheDay }} <!--sagar changes  21-08-2023-->
                <!-- In the absence of clearly-defined goals, we become strangely loyal to performing daily trivia until ultimately we become enslaved by it. -->
                <span style="font-size: 20px;">”</span>
            </div>
            </marquee>
            <div :class="$style.copyright">
                Copyright @ 2018 - {{ getCurrentYear }}
                <a href="#">
                    <img src="../../assets/images/netedge-logo.png" alt=""/>
                </a>
                All Rights Reserved.
            </div>
        </div>
    </div>
</template>

<script>
    import DateUtil from 'Utils/dateUtil';
    import { getThoughtOfTheDay} from "./api";
    export default {
        name: "Footer",
        data() {
    return {
        pageCheck:true,
        thoughtsOfTheDay:'',
    }},
        computed: {
            cssClass() {
                return [this.$style.footer, this.auth ? this.$style.footerAuth : ''].join(' ')
            },
            getCurrentYear() {
                return new Date().getFullYear();
            }
        },
        props: {
            auth: {
                type: Boolean,
                default: false
            }
        },
        created(){
        //     if (this.$route.name==='login') {
        //    this.pageCheck=true;
        //     }
            this.currentDate = DateUtil.formatDate(new Date());
            // sagar call api for fetch thoughts 21-08-2023
            getThoughtOfTheDay().then(res => {
                this.thoughtsOfTheDay=res.data[0].Thought
            });
        },
    };
</script>

<style lang="scss" module>
    .footer {
        position: fixed;
        text-align: right;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        padding: 8px 0 3px;
        z-index: 1;
        &.footerAuth {
            background: #eeedf2;
        }
    }

    .footerDiv{
        display: flex;
    }

    .dateDiv{
        margin-top: 8px;
        width: 150px;
        color: #2e4a9b;
        margin-top: 8px;
    }

    marquee{
        margin: 8px 0px 8px 99px;
        color: #0088bf;
        font-family: system-ui;
        letter-spacing: 0.1rem;
    }

    .copyright {
        font-size: 13px;
        color: #2e4a9b;
        padding-right: 20px;
        width: 650px;

        img {
            max-width: 65px;
            margin: 0 5px;
            vertical-align: middle;
        }
    }
</style>
