<template>
  <div>
    <div :class="$style.topBar">
      <a :class="$style.topBarMenu" href="https://netedgecomputing.com/about-us/" target="_blank">About NetEdge</a>
      <a :class="$style.topBarMenu" href="https://www.linkedin.com/company/netedge-computing-solutions-pvt.-ltd./" target="_blank">Linkedin</a>
      <a :class="$style.topBarMenu" href="https://www.facebook.com/NetedgeComputing" target="_blank">Facebook</a>
      <a :class="$style.topBarMenu" href="https://www.instagram.com/netedge_computing/" target="_blank">Instagram</a>
      <a :class="$style.topBarMenu" href="https://www.google.com/maps/place/NetEdge+Computing/@28.6147663,77.367399,17.2z/data=!4m6!3m5!1s0x390ce567f7215555:0xe0574562d7e7e3ef!8m2!3d28.6143687!4d77.3677942!16s%2Fg%2F1tk61h78?entry=ttu" target="_blank">MyLocation</a>
    </div>
    <header :class="cssClass">
      <router-link to="/dashboard" :class="$style.logo">
          <img src="../../../assets/images/netedge-logo.png" alt="Intranet web Logo" v-if="!isMenuCollapsed"/>
          <div v-if="isMenuCollapsed">
        </div>
      </router-link>
      <div :class="$style.content">
        <div role="button" :class="$style.toggleButton" @click="toggleMenu">
          <Icon type="md-menu" />
        </div>
        <HeaderMenu />
      </div>
    </header>
  </div>
</template>
<script>
import HeaderMenu from "./HeaderMenu";
export default {
  name: "app-header",
  data: function() {
    return {
      userData: "",
    };
  },
  components: {
    HeaderMenu,
  },
  mounted() {
    if (localStorage.getItem("userData")) {
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }
  },
  computed: {
    isMenuCollapsed() {
      return this.$store.state.common.isMenuCollapsed;
    },
    cssClass() {
      return [
        this.$style.header,
        this.isMenuCollapsed ? this.$style.headerCollapsed : "",
      ];
    },
  },
  methods: {
    toggleMenu() {
      this.$store.commit("collapseMenu", !this.isMenuCollapsed);
    },
  },
};
</script>

<style lang="scss" module>
  .topBar {
      // background-image: url("../../../assets/images/bg-globalNav3.jpg");
      background-repeat: no-repeat;
      display: flex;
      justify-content: end;
      padding-right: 20px;
      background-color: #0088bf;
      min-width: -webkit-fill-available;
      height: fit-content;
      color: #ffff;
      background-size: cover;
      column-gap: 80px;
    }

    .topBarMenu {
      font-size: 14px;
      font-weight: 600;
      color: #f0f0f0;
    }
    
  .header {
    height: 232px;
    display: flex;
    position: relative;
  }

  .logo {
    flex-shrink: 0;
    width: 230px;
    background: #eeedf2;
    display: flex;
    // align-items: center;
    justify-content: center;
    transition: width 200ms ease;
    height: 238px;
    img {
      // width: 241px;
      // height: 247px;
      display: block;
      transition: max-width 200ms ease;
      margin-bottom: 10px;
      object-fit: none;
      padding-bottom: 30px;
      .headerCollapsed & {
        max-width: 40px;
      }
    }
    .headerCollapsed & {
      width: 50px;
    }
  }

  .content {
    background: #eeedf2;
    flex-grow: 1;
    position: relative;
    box-shadow: 0 12px 16px #bbbabd87;
  }

  .toggleButton {
    cursor: pointer;
    padding: 12px;
    font-size: 30px;
    color: #81c3ec;
    position: absolute;
    top: -12px;
    left: -12px;
    transition: color 200ms ease;
    &:focus,
    &:hover {
        color: orange
    }
  }

  @media only screen and (max-width: 600px) {
    .header {
      height: 232px;
      display: -webkit-box;
      display: -ms-flexbox;
      /* display: flex; */
      position: relative;
    }
  }
</style>
