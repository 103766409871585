<template>
  <div :class="$style.menu">
    <div :class="$style.topBar1">
      <ul style="list-style: none;display: flex;align-items: center;">
        <li :class="$style.listTop">
          <a  href="BlueBook" :class="$style.topA">Blue Book</a></li>
        <li :class="$style.listTop">
          <a  href="HolidayList" :class="$style.topA">Holiday List</a></li>
        <li :class="$style.listTopL">
          <a  href="SuggestionList" :class="$style.topA">Suggestions</a></li>
    

      <!-- <a :class="$style.listTop">Help</a>
      <a :class="$style.listTop">Help Desk</a> -->
   
      <!-- <a :class="$style.listTop" href="companyCalendar">Calendar</a> -->
    </ul>
    </div>

    <div :class="$style.img2">
      <vueper-slides fade :touchable="false" :slide-ratio="1 / 2" fixed-height="142px" autoplay duration="3000">
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
          :title="slide.title"
          :content="slide.content" />
      </vueper-slides>
    </div>
    <div :class="$style.topMenuBar">
      <div :class="$style.topMenuBarSub">
        <ul
          v-for="(menuItem, i) in menuItem"
          :key="i"
          style="list-style: none;"
        >
          <li :class="$style.listLi">
            <a href="#" @click.prevent="() => sideBar(menuItem.Menu_ID)">
              {{ menuItem.MenuName }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div :class="$style.lastCon">
      <div  style="position: relative; width:35%; text-align: left;"> 
        <span> {{ breadcrumb }} </span>
      </div>
      <div style="position: relative; width:30%; text-align: center;">
        <p>{{ currentTimeStatus }} {{ currentUsername }}</p>
      </div>
      
      <div  style="position: relative; width:35%; text-align: right;">
        <span style="margin-right: 10px; cursor: pointer;">
            <img @click.prevent="() => onClickOfNew()" class="newReleaseNotes" :src="require(`Assets/images/new.gif`)"/>
        </span>
        <a
          href="#"
          style="color: #ffff; margin-right: 10px;"
          @click.prevent="() => changePass()"
          >Change Password</a
        >
        <a @click.prevent="() => logOutUser()" style="color: #ffff; margin-right: 10px">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
import { topMenu, sideMenu, logOut, getHeaderEvents } from "./HeaderMenuApi";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: "HeaderMenu",
  components:{
      VueperSlides,
      VueperSlide
  },
  data() {
    return {
      routes: {
        dashboard: {
          name: "Dashboard",
          path: "/dashboard",
        },
      },
      menuItem: [],
      breadcrumb: "",
      currentTimeStatus: '',
      currentUsername: '',
      slides: [],
      color: 'white'
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  created() {
    this.currentUsername = this.userData.user_name;
    let today = new Date();
    let curHr = today.getHours();
    if (curHr < 12) {
      this.currentTimeStatus = "Good Morning";
    } else if (curHr < 18) {
      this.currentTimeStatus = "Good Afternoon";
    } else {
      this.currentTimeStatus = "Good Evening";
    }
    this.fetchEvents();
    this.fetchMenu();
  },
  methods: {
    onClickOfNew() {
        this.$router.push({ path: `/releaseDetail/${btoa(0)}` });
    },
    sideBar(id) {
      localStorage.setItem("topMenuId", btoa(id));
      sideMenu({ topMenu_id: id }).then((res) => {
        this.$store.commit("subMenu", res.data);
      });
    },
    fetchMenu() {
      topMenu().then(this.handleSuccess);
    },
    fetchEvents() {
      getHeaderEvents().then(this.handleSlidesSuccess);
    },
    handleSlidesSuccess(response) {
      this.slides = response.data;
    },
    handleSuccess(response) {
      this.menuItem = response.data;
      if (localStorage.getItem("topMenuId")) {
        this.sideBar(atob(localStorage.getItem("topMenuId")));
      }else{
        this.sideBar(1); //sagar set default sideBar  id=1 means settings menu 
      }
    
    },
    logOutUser() {
      logOut().then((res) => {
        if (res.status) {
          this.$router.push({ path: "/login" });
          localStorage.clear();
        }
      });
    },
    changePass() {
      this.$router.push({ path: "/changePassword" });
    },
  },
};
</script>

<style lang="scss" module>
  .newReleaseNotes{
      cursor: pointer;
  }

  .vueperslide__title {
    background-color: white
  }

  .img {
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../assets/images/Intranet_Head2.png");
    // background-image: url("../../../assets/images/demonGroup.png");
  }

  .topMenuBar {
    height: 28px;
    vertical-align: top;
    text-align: center;
    // background: linear-gradient(1deg, #f0f0f0, #c2b5b5);
    background: #fff;
  }

  .topBar1 {
    display: flex;
    justify-content: end;
    background: linear-gradient(1deg, #f0f0f0, #c2b5b5);
    height: 28px;
    clip-path: polygon(
      0 0%,
      0% 0%,
      100% 0,
      100% 10%,
      100% 90%,
      100% 100%,
      5% 100%,
      0% -200%,
      0% 30%
    );
  }
.topA{
  color: #494949;
    outline: none;
    font-size: 14px;
    font-weight: 600;
}
  .listLi {
    border-left: 1px solid;
    padding: 5px 40px !important;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    background: linear-gradient(205deg, #f0f0f0, #c2b5b587);
  }

  .listTop {
    border-right: 1px solid;
    padding: 5px 35px !important;
    color: #494949;
    font-weight: bold;
    font-size: 10px;
    font-size: 14px;
    font-weight: 600;
  }

  .listTopL {
    padding: 5px 35px !important;
    color: #494949;
    font-weight: bold;
    font-size: 10px;
    font-size: 14px;
    font-weight: 600;
  }

  .menu {
    right: 15px;
    top: 0;
    height: 100%;
    img {
      vertical-align: middle;
    }
  }

  .breadcrumb {
    background: transparent !important;
    padding: 5px 11px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .content {
    /* display: flex;
          min-height: calc(100vh - 52px); */
    position: absolute;
  }

  .sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    background: #eeedf2;
    width: 230px;
    padding-bottom: 37px;
    transition: width 200ms ease;
    .pageCollapsed & {
      width: 50px;
    }
    height: 645px;
  }

  .breadcrumbs-container {
    background-color: #0088bf;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .arrow {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
  }

  .lastCon {
    display: flex;
    justify-content: space-between;
    height: 37px;
    padding-top: 10px;
    background-color: #0088bf;
    color: #ffff;
  }

  .topMenuBarSub {
    display: flex;
    width: auto;
    justify-content: end;
    background: linear-gradient(205deg, #f0f0f0, #c2b5b587);
  }

  @media only screen and (max-width: 600px) {
    .topBar {
      gap: 21px;
      flex-wrap: wrap;
      justify-content: center;
      padding: 9px 15px;
    }
    .HeaderMenu {
      padding: 5px 35px !important;
      color: #494949;
      font-weight: 700;
      font-size: 10px;
      font-size: 14px;
      font-weight: 600;
      display: block;
    }

    topBar1 {
      height: initial;
    }

    .img {
      height: 28px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../../assets/images/Intranet_Head2.png");
      // background-image: url("../../../assets/images/demonGroup.png");
    }

    .lastCon {
      display: grid;
      padding: 4px;
      background-color: #0088bf;
      color: #ffff;
    }

    .menu {
      width: 755px;
    }

    .topMenuBar {
      height: 90px;
      vertical-align: top;
      text-align: center;
      // background: linear-gradient(1deg, #f0f0f0, #c2b5b5);
      background: #fff;
    }
    
    .topMenuBarSub {
      flex-flow: wrap-reverse;
      display: flex;
      justify-content: end;
      background: linear-gradient(205deg, #f0f0f0, #c2b5b587);
    }
  }
</style>
